export const ActionTypes = {

    FETCH_ALBUMS_REQUEST: "FETCH_ALBUMS_REQUEST_1",
    FETCH_ALBUMS_SUCCESS: "FETCH_ALBUMS_SUCCESS_1",
    FETCH_ALBUMS_ERROR: "FETCH_ALBUMS_ERROR_1",

    FETCH_ALBUM_REQUEST: "FETCH_ALBUM_REQUEST_1",
    FETCH_ALBUM_SUCCESS: "FETCH_ALBUM_SUCCESS_1",
    FETCH_ALBUM_ERROR: "FETCH_ALBUM_ERROR_1",


    DELETE_ALBUM_REQUEST: "DELETE_ALBUM_REQUEST_1",
    DELETE_ALBUM_SUCCESS: "DELETE_ALBUM_SUCCESS_1",
    DELETE_ALBUM_ERROR: "DELETE_ALBUM_ERROR_1",

    SHARE_ALBUM_REQUEST: "SHARE_ALBUM_REQUEST_1",
    SHARE_ALBUM_SUCCESS: "SHARE_ALBUM_SUCCESS_1",
    SHARE_ALBUM_ERROR: "SHARE_ALBUM_ERROR_1",

    CREATE_ALBUMS_REQUEST: "CREATE_ALBUMS_REQUEST_1",
    CREATE_ALBUMS_SUCCESS: "CREATE_ALBUMS_SUCCESS_1",
    CREATE_ALBUMS_ERROR: "CREATE_ALBUMS_ERROR_1",

    EDIT_ALBUMS_REQUEST: "EDIT_ALBUMS_REQUEST_1",
    EDIT_ALBUMS_SUCCESS: "EDIT_ALBUMS_SUCCESS_1",
    EDIT_ALBUMS_ERROR: "EDIT_ALBUMS_ERROR_1",


    LAST_RELOAD_REQUEST: "LAST_RELOAD_REQUEST_1",
    LAST_RELOAD_SUCCESS: "LAST_RELOAD_SUCCESS_1",
    LAST_RELOAD_ERROR: "LAST_RELOAD_ERROR_1",

    LAST_RELOAD_RELOAD_REQUEST: "LAST_RELOAD_RELOAD_REQUEST_1",
    LAST_RELOAD_RELOAD_SUCCESS: "LAST_RELOAD_RELOAD_SUCCESS_1",
    LAST_RELOAD_RELOAD_ERROR: "LAST_RELOAD_RELOAD_ERROR_1",

}