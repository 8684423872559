export const ActionTypes = {

    FETCH_MEDIA_REQUEST: "FETCH_MEDIA_REQUEST_1",
    FETCH_MEDIA_SUCCESS: "FETCH_MEDIA_SUCCESS_1",
    FETCH_MEDIA_ERROR: "FETCH_MEDIA_ERROR_1",

    FETCH_MEDIA_DETAILS_REQUEST_1: "FETCH_MEDIA_DETAILS_REQUEST_1",
    FETCH_MEDIA_DETAILS_SUCCESS_1: "FETCH_MEDIA_DETAILS_SUCCESS_1",
    FETCH_MEDIA_DETAILS_ERROR_1: "FETCH_MEDIA_DETAILS_ERROR_1",

    DELETE_MEDIA_REQUEST: "DELETE_MEDIA_REQUEST",
    DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
    DELETE_MEDIA_ERROR: "DELETE_MEDIA_ERROR",

    CREATE_MEDIA_REQUEST: "CREATE_MEDIA_REQUEST_1",
    CREATE_MEDIA_SUCCESS: "CREATE_MEDIA_SUCCESS_1",
    CREATE_MEDIA_ERROR: "CREATE_MEDIA_ERROR_1",

    FEATURE_MEDIA_REQUEST: "FEATURE_MEDIA_REQUEST",
    FEATURE_MEDIA_SUCCESS: "FEATURE_MEDIA_SUCCESS",
    FEATURE_MEDIA_ERROR: "FEATURE_MEDIA_ERROR",

    SET_ALBUM_COVER_REQUEST: "SET_ALBUM_COVER_REQUEST",
    SET_ALBUM_COVER_SUCCESS: "SET_ALBUM_COVER_SUCCESS",
    SET_ALBUM_COVER_ERROR: "SET_ALBUM_COVER_ERROR",


}

