import * as React from "react"

const PDFIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 102.55 122.88"
        width={20}
        height={20}
        style={{
            enableBackground: "new 0 0 102.55 122.88",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <path
            d="M102.55 122.88H0V0h77.66l24.89 26.43v96.45zM87.01 69.83c-1.48-1.46-4.75-2.22-9.74-2.29-3.37-.03-7.43.27-11.7.86-1.91-1.1-3.88-2.31-5.43-3.75-4.16-3.89-7.64-9.28-9.8-15.22.14-.56.26-1.04.37-1.54 0 0 2.35-13.32 1.73-17.82-.08-.61-.14-.8-.3-1.27l-.2-.53c-.64-1.47-1.89-3.03-3.85-2.94l-1.18-.03c-2.19 0-3.97 1.12-4.43 2.79-1.42 5.24.05 13.08 2.7 23.24l-.68 1.65c-1.9 4.64-4.29 9.32-6.39 13.44l-.28.53c-2.22 4.34-4.23 8.01-6.05 11.13l-1.88 1c-.14.07-3.36 1.78-4.12 2.24-6.41 3.83-10.66 8.17-11.37 11.62-.22 1.1-.05 2.51 1.08 3.16l1.83.9c.79.4 1.62.6 2.47.6 4.56 0 9.87-5.69 17.18-18.44 8.44-2.74 18.04-5.03 26.45-6.29 6.42 3.61 14.3 6.12 19.28 6.12.89 0 1.65-.08 2.27-.25.95-.26 1.76-.8 2.25-1.54.96-1.46 1.16-3.46.9-5.51-.08-.61-.56-1.36-1.09-1.88l-.02.02zm-68.22 24.3c.83-2.28 4.13-6.78 9.01-10.78.3-.25 1.06-.95 1.75-1.61-5.09 8.13-8.51 11.37-10.76 12.39zm28.88-66.49c1.47 0 2.31 3.7 2.38 7.17.07 3.47-.74 5.91-1.75 7.71-.83-2.67-1.24-6.87-1.24-9.62 0-.01-.06-5.26.61-5.26zm-8.62 47.38c1.03-1.83 2.08-3.76 3.17-5.81 2.65-5.02 4.32-8.93 5.57-12.15 2.48 4.51 5.57 8.35 9.2 11.42.45.38.93.77 1.44 1.15-7.38 1.46-13.76 3.23-19.38 5.39zm46.55-.41c-.45.28-1.74.44-2.56.44-2.67 0-5.98-1.22-10.62-3.22 1.78-.13 3.41-.2 4.88-.2 2.68 0 3.48-.01 6.09.66 2.62.67 2.66 2.03 2.21 2.32zm10.52 41.37V30.45H73.44V5.91H6.51v110.07h89.61z"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
            }}
        />
    </svg>
)

export default PDFIcon

