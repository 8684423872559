export const ActionTypes = {

    FETCH_ORDERS_REQUEST: "FETCH_ORDERS_REQUEST_1",
    FETCH_ORDERS_SUCCESS: "FETCH_ORDERS_SUCCESS_1",
    FETCH_ORDERS_ERROR: "FETCH_ORDERS_ERROR_1",

    CREATE_ORDERS_REQUEST: "CREATE_ORDERS_REQUEST_1",
    CREATE_ORDERS_SUCCESS: "CREATE_ORDERS_SUCCESS_1",
    CREATE_ORDERS_ERROR: "CREATE_ORDERS_ERROR_1",

}