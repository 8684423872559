export const ActionTypes = {
    CART_ADD: "CART_ADD",
    CART_REMOVE: "CART_REMOVE",
    CHECKOUT_REQUEST: "CHECKOUT_REQUEST",
    CHECKOUT_SUCCESS: "CHECKOUT_SUCCESS",
    CHECKOUT_ERROR: "CHECKOUT_ERROR",
    STATUS_REQUEST: "STATUS_REQUEST",
    STATUS_SUCCESS: "STATUS_SUCCESS",
    STATUS_ERROR: "STATUS_ERROR",
    ORDERS_REQUEST: "ORDERS_REQUEST",
    ORDERS_SUCCESS: "ORDERS_SUCCESS",
    ORDERS_ERROR: "ORDERS_ERROR",


}