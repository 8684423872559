export const ActionTypes = {
    REQUEST_LOGIN: "REQUEST_LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",


    REQUEST_REGISTER: "REQUEST_REGISTER",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_ERROR: "REGISTER_ERROR",

    REQUEST_FORGOT_PASSWORD: "REQUEST_FORGOT_PASSWORD",
    SUCCESS_FORGOT_PASSWORD: "SUCCESS_FORGOT_PASSWORD",
    ERROR_FORGOT_PASSWORD: "ERROR_FORGOT_PASSWORD",

    REQUEST_SET_PASSWORD: "REQUEST_SET_PASSWORD",
    SUCCESS_SET_PASSWORD: "SUCCESS_SET_PASSWORD",
    ERROR_SET_PASSWORD: "ERROR_SET_PASSWORD",


    LOGOUT: "LOGOUT",
}