export const ActionTypes = {

    FETCH_SETTINGS_REQUEST: "FETCH_SETTINGS_REQUEST_1",
    FETCH_SETTINGS_SUCCESS: "FETCH_SETTINGS_SUCCESS_1",
    FETCH_SETTINGS_ERROR: "FETCH_SETTINGS_ERROR_1",
    EDIT_BANNER_REQUEST: "EDIT_BANNER_REQUEST_1",
    EDIT_BANNER_SUCCESS: "EDIT_BANNER_SUCCESS_1",
    EDIT_BANNER_ERROR: "EDIT_BANNER_ERROR_1",


}