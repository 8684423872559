import * as React from "react"

const ExcelIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 30 30"
        {...props}
    >
        <path d="M15 3a2 2 0 0 0-.4.043l-.002-.002-9.97 1.994-.001.002A2 2 0 0 0 3 7v16a2 2 0 0 0 1.629 1.965l9.969 1.994A2 2 0 0 0 15 27a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm4 2v3h2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-2v3h6a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-6zm4 3h1a1 1 0 0 1 0 2h-1V8zM6.186 10h2.402l1.244 2.99c.101.244.182.527.26.848h.033c.045-.193.134-.486.27-.871L11.785 10h2.188l-2.614 4.955 2.692 5.043h-2.334l-1.504-3.258c-.057-.115-.124-.347-.18-.668h-.021a7.783 7.783 0 0 1-.203.696L8.295 20H5.949l2.783-5.006L6.186 10zM23 12h1a1 1 0 0 1 0 2h-1v-2zm0 4h1a1 1 0 0 1 0 2h-1v-2zm0 4h1a1 1 0 0 1 0 2h-1v-2z" />
    </svg>
)

export default ExcelIcon
