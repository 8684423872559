export const ActionTypes = {

    FETCH_GROUPS_REQUEST: "FETCH_GROUPS_REQUEST_1",
    FETCH_GROUPS_SUCCESS: "FETCH_GROUPS_SUCCESS_1",
    FETCH_GROUPS_ERROR: "FETCH_GROUPS_ERROR_1",

    CREATE_GROUPS_REQUEST: "CREATE_GROUPS_REQUEST_1",
    CREATE_GROUPS_SUCCESS: "CREATE_GROUPS_SUCCESS_1",
    CREATE_GROUPS_ERROR: "CREATE_GROUPS_ERROR_1",

}